export async function loadStrings() {
  let lang = getLanguageCode();

  try {
    return await loadByLang(lang);
  }
  catch {
    return await loadByLang(window.default_language);
  }
}

function getLanguageCode() {
  return window.navigator.language.toLowerCase().split('-')[0];
}

export function getLanguage() {
  let lang = getLanguageCode();
  return window.available_languages.includes(lang) ? lang : window.default_language;
}

async function loadByLang(lang) {
  let def = await (await fetch(`/strings/strings.json`)).json();
  window.available_languages = def.available_languages;
  window.default_language = def.default_language;
  
  let res = await (await fetch(`/strings/strings.${lang}.json`)).json();
  window.global_strings = {
    ...def,
    ...res,
  };
}

export default function Strings() {
  return window.global_strings;
}