import * as React from 'react';
import '../css/development.css';
import Strings from '../strings';

export default function Development() {
  return (
    <div id='development-container'>
      <div id='development-scroll-anchor' className='section-scroll-anchor'></div>
      <div className='text-large lighter-text'>{Strings().development_title}</div>
      <div className='text-xxlarge lighter-text'>{Strings().development_description}</div>
      <div id='development-video' className='margin-vertical'>
        <iframe src={Strings().url_teaser_video} title='YouTube video' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' allowfullscreen></iframe>
      </div>
    </div>
  )
}