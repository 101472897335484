import * as React from 'react';
import '../css/wrap-up.css';
import Strings from '../strings';
import { subscribeEmail } from '../services/subscription';
import ArrowIcon from '../components/ArrowIcon';

export default function WrapUp() {
  React.useEffect(setupPrivacyPolicyLink);

  return (
    <div id='wrap-up-container'>
      <div className='text-large'>{Strings().wrap_up_title}</div>
      <div id='wrap-up-content'>
        <div className='text-xxlarge'>{Strings().wrap_up_description}</div>
        <SubscribeEmail />
        <div className='margin-vertical text-small light-text' dangerouslySetInnerHTML={{ __html: Strings().wrap_up_privacy_policy }}></div>
        <div id='socials-container' className='margin-vertical'>
          <a className='social tiktok' target='__blank' href={Strings().link_tiktok}></a>
          <a className='social discord' target='__blank' href={Strings().link_discord}></a>
          <a className='social instagram' target='__blank' href={Strings().link_instagram}></a>
          <a className='social twitter' target='__blank' href={Strings().link_twitter}></a>
        </div>
        <div id='copyright'>{Strings().wrap_up_copyright}</div>
      </div>
    </div>
  )
}

function SubscribeEmail() {
  const [status, setStatus] = React.useState('empty');

  const handleSubscribe = (e) => {
    e.preventDefault();
    let email = document.getElementById('input-email');

    if (status === 'ready') {
      setStatus('submitted');
      email.blur();
      email.disabled = true;

      subscribeEmail(email.value)
      .then(() => {
        setStatus('success');
      })
      .catch(() => {
        setStatus('failure');
        email.disabled = false;
        email.value = Strings().email_invalid;
      });
    }
  }

  const handleFocus = (e) => {
    if (status === 'failure') {
      e.target.value = '';
      setStatus('empty');
    }
  }

  const handleInputChange = (e) => {
    if (status === 'empty' && e.target.value) {
      setStatus('ready');
    }
    else if (!e.target.value) {
      setStatus('empty');
    }
  }

  if (status === 'success') {
    return (
      <div className='email-finished-text text-xlarge margin-top'>{Strings().email_finished}</div>
    )
  }

  return (
    <>
      <div className='margin-vertical text-small'>{Strings().wrap_up_subscribe_text}</div>

      <form id='form-email' onSubmit={handleSubscribe}>
        <div id='input-email-container' className={status}>
          <input id='input-email' className={`text-medium ${status}`} type='text' placeholder={Strings().email_placeholder} onFocus={handleFocus} onInput={handleInputChange} />
          <div id='input-email-submit' className={status} onClick={handleSubscribe}><ArrowIcon /></div>
        </div>
      </form>
    </>
  )
}

function setupPrivacyPolicyLink() {
  let privacyPolicy = document.getElementById('privacy_policy_link');
  privacyPolicy.href = Strings().link_privacy_policy;
  privacyPolicy.target = '__blank';
  privacyPolicy.classList.add('regular-text');
}