import * as React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../css/skins.css';
import Strings from '../strings';

const images = require.context('../images/skins', true);
const imageList = images.keys().map(image => images(image));

const responsive = {
  desktop: {
    breakpoint: { max: 10000, min: 801 },
    items: 5,
  },
  mobile: {
    breakpoint: { max: 800, min: 0 },
    items: 5,
  },
};

export default function Skins() {

  React.useEffect(() => {
    let itemContainer = document.getElementsByClassName('skins-carousel')[0];
    itemContainer.addEventListener('pointermove', onPointerMove);
  });

  return (
    <div id='skins-container' className='diagonal-clip-left'>
      <div id='skins-scroll-anchor' className='section-scroll-anchor'></div>

      <div id='skins-content'>
        <div id='skins-texts'>
          <div className='text-large lighter-text'>{Strings().characters_title}</div>
          <div className='text-xxlarge lighter-text'>{Strings().skins_description}</div>
          <div className='margin-top'></div>
          <div className='text-small lighter-text'>{Strings().skins_main_text}</div>
        </div>

        <div id='skins-carousel-container'>
          <Carousel
          ref={el => (window.skinsCarousel = el)}
          responsive={responsive}
          beforeChange={beforeChangeSlide}
          containerClass='skins-carousel'
          itemClass='skins-carousel-item'
          removeArrowOnDeviceType='mobile'
          draggable={false}
          swipeable={false}
          customTransition='transform 300ms ease-in-out'
          transitionDuration={300}
          customLeftArrow={<LeftArrow />}
          customRightArrow={<RightArrow />}>
            {imageList.map((image, index) => (
              <SkinCard key={index} image={image} index={index} />
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  )
}

function SkinCard({ image, index }) {

  React.useEffect(() => {
    updateCarouselItem(index, 0);
  });

  return (
    <div id={`skins-card-${index}`} className='skins-card'>
      <img src={image} />
    </div>
  );
}

function LeftArrow({ onClick }) {
  return (
    <div className='skins-carousel-arrow skins-carousel-arrow-left' onClick={onClick}></div>
  );
}

function RightArrow({ onClick }) {
  return (
    <div className='skins-carousel-arrow skins-carousel-arrow-right' onClick={onClick}></div>
  );
}

function onPointerMove(event) {
  if (event.buttons != 1) {
    return;
  }

  if (event.movementX == 0 || Math.abs(event.movementX) < Math.abs(event.movementY)) {
    return;
  }

  let direction = Math.sign(event.movementX);

  if (direction < 0) {
    window.skinsCarousel.next(1);
  }
  else {
    window.skinsCarousel.previous(1);
  }
}

function updateCarouselItem(index, nextSlide) {
  let item = document.getElementById(`skins-card-${index}`).parentElement;
  let centerSlide = nextSlide + 2;
  let distance = Math.abs(index - centerSlide);
  let direction = Math.sign(index - centerSlide);

  item.dataset.distance = distance;
  item.dataset.direction = direction;
}

function beforeChangeSlide(nextSlide, { currentSlide }) {

  let centerSlide = nextSlide + 2;
  let lastSlide = currentSlide + 4;

  updateCarouselItem(currentSlide, nextSlide);
  updateCarouselItem(lastSlide, nextSlide);
  updateCarouselItem(centerSlide - 2, nextSlide);
  updateCarouselItem(centerSlide - 1, nextSlide);
  updateCarouselItem(centerSlide, nextSlide);
  updateCarouselItem(centerSlide + 1, nextSlide);
  updateCarouselItem(centerSlide + 2, nextSlide);
}