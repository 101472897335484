import React from 'react';
import ReactDOM from 'react-dom/client';
import IndexPage from './pages/index';
import { loadStrings } from './strings';

const root = ReactDOM.createRoot(document.getElementById('root'));

async function run() {
  await setup();
  render();
}

async function setup() {
  await loadStrings();
}

function render() {
  root.render(
    <React.StrictMode>
      <IndexPage />
    </React.StrictMode>
  );
}

run();