import * as React from 'react';
import '../css/splash.css';
import { useAnimation } from '../hooks/animation';

export default function Splash() {
  useAnimation(() => ({
    target: 'splash-container',
    anchor: { start: 'header-scroll-anchor' },
    keyframes: {
      100: { top: { from: 0, to: parseInt(getComputedStyle(document.documentElement)
        .getPropertyValue('--splash-parallax')), unit: 'vw' } },
    }
  }));

  return (
    <div id='splash-container'>
      <div id='splash-bg'></div>
      <div id='splash-characters'></div>
    </div>
  )
}