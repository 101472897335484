import * as React from 'react';
import Motus from 'motus';

export const useAnimation = (arg) => {
  React.useEffect(() => {
    const {
      target,
      anchor: {
        start,
        end,
        onBetween,
        onBefore,
        onAfter,
      } = {},
      keyframes = [{}]
    } = typeof arg === 'function' ? arg() : arg;

    const el = document.getElementById(target);

    const newAnimation = new Motus.Animation({
      $el: el,
      startPoint: point(start),
      endPoint: point(end),
      onScrollBetween: onBetween ? () => onBetween(el) : undefined,
      onScrollBefore: onBefore ? () => onBefore(el) : undefined,
      onScrollAfter: onAfter ? () => onAfter(el) : undefined,
      keyframes,
     });
     Motus.addAnimation(newAnimation);

     return () => {
      Motus.clearAnimation(newAnimation);
     }
  });
}

function point(p) {
  if (typeof p === 'string') return document.getElementById(p);
  return p;
}