import * as React from 'react';
import '../css/characters.css';
import { useAnimation } from '../hooks/animation';
import Strings from '../strings';

export default function Characters() {
  useCharAnimations();

  return (
    <div id='characters-container'>
      <div id='characters-scroll-anchor' className='section-scroll-anchor'></div>

      <div className='text-xxlarge characters-section-text'>{Strings().characters_description}</div>
      <div className='margin-top'></div>
      <div className='text-small characters-section-text'>{Strings().characters_main_text}</div>

      <div id='char-box-1' className='char-box char-1 diagonal-clip-left char-hidden'>
        <div id='char-scroll-anchor-1' className='char-scroll-anchor'></div>
        <div className='char-bio char-1 margin-top'>
          <div id='char-name-1' className='char-name char-1 text-xxlarge'>{Strings().characters_1_name}</div>
          <div id='char-description-1' className='char-description char-1 text-small margin-top'>{Strings().characters_1_description}</div>
        </div>
        <div id='char-circle-1' className='char-circle char-1'></div>
        <video id='char-video-1' className='char-video char-1' loop muted playsInline>
          <source
            src='/videos/Norman_HEVC.mov'
            type='video/mp4; codecs="hvc1"' />
          <source
            src='/videos/Norman_VP9.webm'
            type='video/webm' />
        </video>
      </div>

      <div id='char-box-2' className='char-box char-2 diagonal-clip-right char-hidden'>
        <div id='char-scroll-anchor-2' className='char-scroll-anchor'></div>
        <div className='char-bio char-2 margin-top'>
          <div id='char-name-2' className='char-name char-2 text-xxlarge'>{Strings().characters_2_name}</div>
          <div id='char-description-2' className='char-description char-2 text-small margin-top'>{Strings().characters_2_description}</div>
        </div>
        <div id='char-circle-2' className='char-circle char-2'></div>
        <video id='char-video-2' className='char-video char-2' loop muted playsInline>
          <source
            src='/videos/Cookie_HEVC.mov'
            type='video/mp4; codecs="hvc1"' />
          <source
            src='/videos/Cookie_VP9.webm'
            type='video/webm' />
        </video>
      </div>

      <div id='char-box-3' className='char-box char-3 diagonal-clip-left char-hidden'>
        <div id='char-scroll-anchor-3' className='char-scroll-anchor'></div>
        <div className='char-bio char-3 margin-top'>
          <div id='char-name-3' className='char-name char-3 text-xxlarge'>{Strings().characters_3_name}</div>
          <div id='char-description-3' className='char-description char-3 text-small margin-top'>{Strings().characters_3_description}</div>
        </div>
        <div id='char-circle-3' className='char-circle char-3'></div>
        <video id='char-video-3' className='char-video char-3' loop muted playsInline>
          <source
            src='/videos/Winnie_HEVC.mov'
            type='video/mp4; codecs="hvc1"' />
          <source
            src='/videos/Winnie_VP9.webm'
            type='video/webm' />
        </video>
      </div>
    </div>
  )
}

const useCharAnimations = () => {
  useAnimation({
    target: 'char-box-1',
    anchor: {
      start: 'char-scroll-anchor-1',
      end: 'char-scroll-anchor-1',
      onBefore: hideChar,
      onAfter: showChar,
    }
  });

  useAnimation({
    target: 'char-box-2',
    anchor: {
      start: 'char-scroll-anchor-2',
      end: 'char-scroll-anchor-2',
      onBefore: hideChar,
      onAfter: showChar,
    }
  });

  useAnimation({
    target: 'char-box-3',
    anchor: {
      start: 'char-scroll-anchor-3',
      end: 'char-scroll-anchor-3',
      onBefore: hideChar,
      onAfter: showChar,
    }
  });

  useAnimation({
    target: 'char-description-1',
    anchor: {
      end: 'char-name-1',
      onBetween: () => playChar(1),
      onBefore: () => pauseChar(1),
      onAfter: () => pauseChar(1),
    }
  });

  useAnimation({
    target: 'char-description-2',
    anchor: {
      end: 'char-name-2',
      onBetween: () => playChar(2),
      onBefore: () => pauseChar(2),
      onAfter: () => pauseChar(2),
    }
  });

  useAnimation({
    target: 'char-description-3',
    anchor: {
      end: 'char-name-3',
      onBetween: () => playChar(3),
      onBefore: () => pauseChar(3),
      onAfter: () => pauseChar(3),
    }
  });
}

const showChar = (el) => {
  el.classList.remove('char-hidden');
}

const hideChar = (el) => {
  el.classList.add('char-hidden');
}

const playChar = (id) => {
  let video = document.getElementById(`char-video-${id}`);
  if (video.paused) {
    video.play();
  }
}

const pauseChar = (id) => {
  let video = document.getElementById(`char-video-${id}`);
  if (!video.paused) {
    video.pause();
  }
}