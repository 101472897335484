import * as React from 'react';
import '../css/join-us.css';
import DiscordButton from '../components/DiscordButton';
import Strings from '../strings';

export default function JoinUs() {
  return (
    <div id='join-us-container'>
      <div className='text-large'>{Strings().join_us_title}</div>
      <div className='text-xxlarge'>{Strings().join_us_description}</div>
      <div className='text-medium margin-vertical'>{Strings().join_us_text}</div>
      <div className='margin-top'></div>
      <DiscordButton color='light' size='large' />
    </div>
  )
}