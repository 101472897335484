import * as React from 'react';
import '../css/logo.css';
import imgLogo from '../images/logo.png';
import { scrollToTop } from '../services/scroll';
import { useAnimation } from '../hooks/animation';

export default function Logo() {
  useAnimation({
    target: 'logo-body-container',
    anchor: {
      start: 'header-scroll-anchor',
      onBefore: el => el.classList.remove('logo-hidden'),
      onAfter: el => el.classList.add('logo-hidden'),
    }
  });

  useAnimation({
    target: 'logo-header-container',
    anchor: {
      start: 'header-scroll-anchor',
      onBefore: el => el.classList.add('logo-hidden'),
      onAfter: el => el.classList.remove('logo-hidden'),
    }
  });

  return (
    <>
      <div id='logo-body-container'>
        <img id='logo-body' src={imgLogo} alt='Arena of Dreams'></img>
      </div>

      <div id='logo-header-container' className='logo-hidden' onClick={scrollToTop}>
        <img id='logo-header' src={imgLogo} alt='Arena of Dreams'></img>
      </div>
    </>
  )
}