import * as React from 'react';
import '../css/index.css';
import Header from './Header';
import Logo from './Logo';
import Splash from './Splash';
import LetsPlay from './LetsPlay';
import Characters from './Characters';
import Development from './Development';
import JoinUs from './JoinUs';
import WrapUp from './WrapUp';
import Skins from './Skins';
import Minigames from './Minigames';

export default function IndexPage() {
  return (
    <>
      <Header />
      <Logo />
      <Splash />
      <LetsPlay />
      <Development />
      <Skins />
      <Characters />
      <Minigames />
      <JoinUs />
      <WrapUp />
    </>
  )
}