export function scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}

export function scrollToLetsPlay() {
  scrollToElement('lets-play-scroll-anchor');
}

export function scrollToCharacters() {
  scrollToElement('skins-scroll-anchor');
}

export function scrollToDevelopment() {
  scrollToElement('development-scroll-anchor');
}

export function scrollToMinigames() {
  scrollToElement('minigames-scroll-anchor');
}

function scrollToElement(id) {
  let element = document.getElementById(id),
    bodyRect  = document.body.getBoundingClientRect(),
    elemRect  = element.getBoundingClientRect(),
    offset    = elemRect.top - bodyRect.top;

  window.scrollTo({ top: offset + 20, behavior: 'smooth' });
}