import * as React from 'react';
import '../css/discord-button.css';
import Strings from '../strings';
import DiscordIcon from './DiscordIcon';

/**
 * @param {{
 *  color: 'light'|'dark',
 *  size: 'regular'|'large'
 * }} props
 */
export default function DiscordButton({ color, size, ...rest }) {
  const realSize = size || 'regular';
  const buttonClassName = `discord-button ${color} ${realSize}`;
  const textClassName = `discord-button-text ${color} ${realSize}`;

  return (
    <a {...rest} className={buttonClassName} href={Strings().link_discord} target='__blank'>
      <div className='discord-button-icon'><DiscordIcon /></div>
      <span className={textClassName}>{Strings().discord_button}</span>
    </a>
  )
}