import * as React from 'react';
import '../css/lets-play.css';
import Strings from '../strings';
import StoreBadge from '../components/StoreBadge';

export default function LetsPlay() {
  return (
    <div id='lets-play-container'>
      <div id='lets-play-scroll-anchor' className='section-scroll-anchor'></div>

      <div className='text-large'>{Strings().lets_play_title}</div>
      <div className='text-xxlarge' dangerouslySetInnerHTML={{ __html: Strings().lets_play_description }}></div>
      <a className='download-container' href={Strings().link_download} target='_blank'>
        <StoreBadge store='app_store' />
        <img id='qr-code-download' src='/images/qr_code_download.png' />
        <StoreBadge store='google_play' />
      </a>
      <div className='margin-vertical text-small'>{Strings().lets_play_text_2}</div>
    </div>
  )
}