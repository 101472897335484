import * as React from 'react';
import '../css/header.css';
import Strings from '../strings';
import { scrollToCharacters, scrollToLetsPlay, scrollToMinigames } from '../services/scroll';
import { useAnimation } from '../hooks/animation';
import iconMenu from '../images/icon_menu.svg';

export default function Header() {

  const setSelected = (target, type) => {
    let selected = document.getElementsByClassName(`${type}-link selected`);
    for (let s of selected) {
      s.classList.remove('selected');
    }

    document.getElementById(target).classList.add('selected');
  }

  useAnimation({
    target: 'header',
    anchor: {
      start: 'header-scroll-anchor',
      onBefore: el => el.classList.remove('expanded'),
      onAfter: el => el.classList.add('expanded'),
    }
  });

  useAnimation({
    target: 'header-link-chars',
    anchor: {
      start: 'skins-scroll-anchor',
      onBefore: () => setSelected('header-link-play', 'header'),
      onAfter: () => setSelected('header-link-chars', 'header'),
    }
  });

  useAnimation({
    target: 'header-link-dev',
    anchor: {
      start: 'minigames-scroll-anchor',
      onAfter: () => setSelected('header-link-dev', 'header'),
    }
  });

  useAnimation({
    target: 'menu-link-chars',
    anchor: {
      start: 'skins-scroll-anchor',
      onBefore: () => setSelected('menu-link-play', 'menu'),
      onAfter: () => setSelected('menu-link-chars', 'menu'),
    }
  });

  useAnimation({
    target: 'menu-link-dev',
    anchor: {
      start: 'minigames-scroll-anchor',
      onAfter: () => setSelected('menu-link-dev', 'menu'),
    }
  });

  return (
    <>
      <div id='header-scroll-anchor'></div>
      <header id='header'>
        <div id='header-left-container'>
          <img onClick={toggleMenu} className='header-menu-button' src={iconMenu} />
        </div>
        <div id='header-links-container'>
          <div id='header-link-play' className='header-link' onClick={scrollToLetsPlay}>{Strings().header_section_lets_play}</div>
          <div id='header-link-chars' className='header-link' onClick={scrollToCharacters}>{Strings().header_section_characters}</div>
          <div id='header-link-dev' className='header-link' onClick={scrollToMinigames}>{Strings().header_section_development}</div>
        </div>
        <div id='header-right-container'>
          <a href={Strings().link_download} target='_blank' id='header-menu-download'>
            <img src='/images/app_store_icon_button.svg' className='header-menu-button' />
            <img src='/images/google_play_icon_button.svg' className='header-menu-button' />
          </a>
        </div>
      </header>
      <div id='menu-links-container'>
        <div id='menu-overlay' className='menu-hidden' onClick={toggleMenu}></div>
        <div id='menu-content' className='menu-hidden'>
          <div id='menu-link-play' className='menu-link' onClick={() => {toggleMenu(); scrollToLetsPlay();}}>{Strings().header_section_lets_play}</div>
          <div id='menu-link-chars' className='menu-link' onClick={() => {toggleMenu(); scrollToCharacters();}}>{Strings().header_section_characters}</div>
          <div id='menu-link-dev' className='menu-link' onClick={() => {toggleMenu(); scrollToMinigames();}}>{Strings().header_section_development}</div>
        </div>
      </div>
    </>
  )
}

function toggleMenu() {
  let menu = document.getElementById('menu-content');
  menu.classList.toggle('menu-hidden');

  let overlay = document.getElementById('menu-overlay');
  overlay.classList.toggle('menu-hidden');
}