import * as React from 'react';
import '../css/store-badge.css';
import { getLanguage } from '../strings';

const EXTENSIONS = {
  app_store: 'svg',
  google_play: 'png',
};

export default function StoreBadge({ store }) {
  let lang = getLanguage();
  let ext = EXTENSIONS[store];

  return (
    <img src={`/images/${store}_badge_${lang}.${ext}`} className='store-badge' />
  )
}