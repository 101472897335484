import * as React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../css/minigames.css';
import Strings from '../strings';

const images = require.context('../images/minigames', true);
const imageList = images.keys().map(key => ({ key: key.match(/\d{2}/)[0], image: images(key) }));

const responsive = {
  desktop: {
    breakpoint: { max: 10000, min: 801 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 800, min: 0 },
    items: 1,
  },
};

export default function Minigames() {

  React.useEffect(() => {
    let itemContainer = document.getElementsByClassName('minigames-carousel')[0];
    itemContainer.addEventListener('pointermove', onPointerMove);
  });

  return (
    <div id='minigames-container'>
      <div id='minigames-scroll-anchor' className='section-scroll-anchor'></div>

      <div id='minigames-texts'>
        <div className='text-large lighter-text'>{Strings().minigames_title}</div>
        <div className='text-xxlarge lighter-text'>{Strings().minigames_description}</div>
        <div className='margin-top'></div>
        <div className='text-small lighter-text'>{Strings().minigames_main_text}</div>
      </div>

      <div id='minigames-content' className='diagonal-clip-left'>
        <div id='minigames-carousel-container'>
          <Carousel
          ref={el => (window.minigamesCarousel = el)}
          responsive={responsive}
          containerClass='minigames-carousel'
          itemClass='minigames-carousel-item'
          dotListClass='minigames-carousel-dot-list'
          removeArrowOnDeviceType='mobile'
          draggable={false}
          swipeable={false}
          showDots={true}
          customTransition='transform 300ms ease-in-out'
          transitionDuration={300}
          customLeftArrow={<LeftArrow />}
          customRightArrow={<RightArrow />}
          customDot={<Dot />}>
            {imageList.map((image, index) => (
              <MinigameCard key={index} image={image} index={index} />
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  )
}

function MinigameCard({ image, index }) {

  return (
    <div id={`minigames-card-${index}`} className='minigames-card'>
      <div className='minigames-card-texts'>
        <div className='text-xxlarge minigames-card-title'>{Strings()[`minigame_title_${image.key}`]}</div>
        <div className='margin-top'></div>
        <div className='text-small minigames-card-description'>{Strings()[`minigame_description_${image.key}`]}</div>
      </div>
      <img className='minigames-card-thumb' src={image.image} />
    </div>
  );
}

function LeftArrow({ onClick }) {
  return (
    <div className='minigames-carousel-arrow minigames-carousel-arrow-left' onClick={onClick}></div>
  );
}

function RightArrow({ onClick }) {
  return (
    <div className='minigames-carousel-arrow minigames-carousel-arrow-right' onClick={onClick}></div>
  );
}

function Dot({ onClick, active }) {
  return (
    <div className='minigames-carousel-dot' onClick={onClick} data-active={active}></div>
  );
}

function onPointerMove(event) {
  if (event.buttons != 1) {
    return;
  }

  if (event.movementX == 0 || Math.abs(event.movementX) < Math.abs(event.movementY)) {
    return;
  }

  let direction = Math.sign(event.movementX);

  if (direction < 0) {
    window.minigamesCarousel.next(1);
  }
  else {
    window.minigamesCarousel.previous(1);
  }
}